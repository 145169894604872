<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <vs-popup classContent="popup-example" title="Modifier le Fond de caisse" :active.sync="popupFonddecaisseUpdate">

        <div class="vx-row">
          <div class="vx-col w-full">
            <div class="flex items-end px-3">
              <feather-icon svgClasses="w-6 h-6" icon="PlusSquareIcon" class="mr-2" />
              <span class="font-medium text-lg leading-none">Modifier le Fond de caisse</span>
            </div>
            <vs-divider />
          </div>
        </div>

       <!--  Officine et Utilisateur-->
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/2 w-full">
            <vs-select autocomplete label="officine" class="ligne-w" v-model="IdOfficine" >
              <vs-select-item v-for="item in officineData" :key="item._id" :value="item._id" :text="item.officine"/>
            </vs-select>
            <span class="text-danger text-sm" v-show="errors.has('officine')">{{ errors.first('officine') }}</span>
          </div>

          <div class="vx-col sm:w-1/2 w-full">
            <vs-select autocomplete label="utilisateur" class="ligne-w" v-model="IdUser" >
              <vs-select-item v-for="item in userData" :key="item._id" :value="item._id" :text="item.loginU"/>
            </vs-select>
            <span class="text-danger text-sm" v-show="errors.has('user')">{{ errors.first('user') }}</span>
          </div>
        </div>
        <!--  les dates de début de setion-->
        <vs-divider position="center">
          <feather-icon svgClasses="w-6 h-6" icon="" class="mr-2" />
          <span class="font-medium text-lg leading-none">Période d'exercice</span>
        </vs-divider>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/2 w-full">
            <div class="mt-4">
             <label class="text-sm">Date de debut d'exercice</label>
             <flat-pickr v-model="DateDebutExercice" :config="{ dateFormat: 'd F Y'}" class="w-full" v-validate="'required'" name="DateDebutExercice" />
             <span class="text-danger text-sm"  v-show="errors.has('naissance')">{{ errors.first('naissance') }}</span>
            </div>
          </div>

          <div class="vx-col sm:w-1/2 w-full">
            <div class="mt-4">
             <label class="text-sm">Date de fin d'exercice</label>
             <flat-pickr v-model="DateFinExercice" :config="{ dateFormat: 'd F Y'}" class="w-full" v-validate="'required'" name="DateFinExercice" />
             <span class="text-danger text-sm"  v-show="errors.has('DateFinExercice')">{{ errors.first('DateFinExercice') }}</span>
            </div>
          </div>

        </div>
        <!--  Montant du fond de caisse-->
        <vs-divider position="center">
          <feather-icon svgClasses="w-6 h-6" icon="" class="mr-2" />
          <span class="font-medium text-lg leading-none">Fond de caisse</span>
        </vs-divider>

        <div class="vx-row mb-6">
         <div class="vx-col w-full">
            <vs-input class="w-full"  type="number" label="Montant du fond de caisse" v-validate="'required'" name="Mont" v-model="Mont" />
            <span class="text-danger text-sm"  v-show="errors.has('Mont')">{{ errors.first('Mont') }}</span>
         </div>
        </div>

        <div class="vx-row">
          <div class="vx-col sm:w-2/3 w-full ml-auto">
            <vs-button class="mr-3 mb-2" @click="updateFonddecaisse" :disabled="!validateForm">Modifier</vs-button>
            <vs-button color="warning" type="border" class="mb-2" @click="reset_data" popupFonddecaisseUpdate = "false" >Annuler</vs-button>
          </div>
        </div>

      </vs-popup>
      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="updateFonddecaisseShowForm" />
      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
    </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  data () {
    return {
      popupFonddecaisseUpdate: false,
      IdOfficine:'',
      IdUser: '',
      Mont: '',
      DateDebutExercice: '',
      DateFinExercice: ''
    }
  },
  computed:{
    validateForm () {
      return !this.errors.any() && this.Mont !== '' & this.DateDebutExercice !== '' & this.DateFinExercice !== '' & this.IdOfficine !== '' & this.IdUser !== ''
    }
    /*    // les données des combobox
    fonctionData () {
      return this.$store.state.fonction.fonctions
    },
    FonddecaisseData () {
      return this.$store.state.personnel.personnels
    }*/
  },
  methods: {
    updateFonddecaisseShowForm () {
      this.Mont = this.params.data.Mont
      this.DateDebutExercice = this.params.data.DateDebutExercice
      this.DateFinExercice = this.params.data.DateFinExercice
      this.IdOfficine = this.params.data.IdOfficine
      this.IdUser = this.params.data.IdUser

      this.popupFonddecaisseUpdate = true
    },
    updateFonddecaisse () {
      const payload = {

        Mont: this.params.data.Mont,
        DateDebutExercice: this.params.data.DateDebutExercice,
        DateFinExercice: this.params.data.DateFinExercice,
        IdOfficine: this.params.data.IdOfficine,
        IdUser: this.params.data.IdUser,

        // status: this.status,
        id: this.params.data._id
      }

      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('fonddecaisse/updateFonddecaisse', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success'
          })
          //this.popupFonddecaisse = false
          this.resete_data()
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.$vs.loading.close()
          this.popupFonddecaisseUpdate = false
        })
    },

    reset_data () {

      this.Mont = ''
      this.DateDebutExercice = ''
      this.DateFinExercice = ''
      this.IdOfficine = ''
      this.IdUser = ''

    },

    editRecord () {
      this.$router.push(`/apps/fonddecaisse/fonddecaisse-edit/${  268}`).catch(() => {})
      /*
        Below line will be for actual product
        Currently it's commented due to demo purpose - Above url is for demo purpose

        this.$router.push("/apps/user/user-edit/" + this.params.data.id).catch(() => {})
      */
    },
    confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmation de suppression',
        text: `Vous allez supprimer le fond de caisse "${this.params.data.Mont}"`,
        accept: this.deleteRecord,
        acceptText: 'Supprimer'
      })
    },
    deleteRecord () {
      /* Below two lines are just for demo purpose */
      // this.showDeleteSuccess()

      /* UnComment below lines for enabling true flow if deleting user */
      this.$store.dispatch('fonddecaisse/removeFonddecaisse', this.params.data._id)
        .then(()   => { this.showDeleteSuccess() })
        .catch(err => { console.error(err)       })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: 'Fond de caisse Supprimé',
        text: 'Le fond de caisse selectionné a bien été supprimé'
      })
    }
  }
}
</script>
